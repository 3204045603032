.dashboard-container {
    min-height: 100vh;
    display: flex;
    background-color: white;
  }
  
  .main-content {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    background-color: white;
  }
  
  .main-header {
    background-color: #062238;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .main-title {
    color: white;
    font-size: 1.5rem;
    font-weight: bold;
  }
  
  .user-button {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    transition: color 0.3s ease;
  }
  
  .user-button:hover {
    color: #4a90e2;
  }
  
  .dashboard-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
    padding: 1rem;
  }
  
  .dashboard-card {
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    border: 1px solid #e2e8f0;
  }
  
  .dashboard-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15);
  }
  
  .card-title {
    color: #062238;
    font-size: 1.25rem;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }
  
  .card-content {
    color: #4a5568;
  }
  
  .stat-value {
    font-size: 2rem;
    font-weight: bold;
    color: #4a90e2;
    margin-bottom: 0.5rem;
  }
  
  .stat-label {
    color: #718096;
    font-size: 0.875rem;
  }