.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.dashboard-card {
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.edit-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.edit-input {
  padding: 0.5rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.25rem;
  font-size: 1rem;
}

.edit-actions {
  display: flex;
  gap: 0.5rem;
  justify-content: flex-end;
  margin-top: 1rem;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.action-buttons {
  display: flex;
  gap: 0.5rem;
}

.save-button,
.cancel-button,
.edit-button,
.delete-button,
.add-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.save-button,
.edit-button {
  background-color: #007BFF; /* Couleur bleue */
  color: white;
}

.edit-button:hover {
  background-color: #0056b3; /* Bleu plus foncé au survol */
}

.add-button {
  background-color: #4CAF50;
  color: white;
}

.save-button:hover,

.add-button:hover {
  background-color: #45a049;
}

.cancel-button,
.delete-button {
  background-color: #f44336;
  color: white;
}

.cancel-button:hover,
.delete-button:hover {
  background-color: #e53935;
}

.add-section {
  display: flex; /* Ensures the button alignment works */
  justify-content: flex-end; /* Aligns the button to the right */
  padding: 2rem 2rem 0 2rem;
}


.card-title {
  margin: 0;
  font-size: 1.25rem;
  color: #1a202c;
}

