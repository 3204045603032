.slide-panel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease;
  }
  
  .slide-panel-overlay.active {
    opacity: 1;
    pointer-events: auto;
  }
  
  .slide-panel {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 400px;
    background: white;
    box-shadow: -2px 0 4px rgba(0,0,0,0.1);
    transform: translateX(100%);
    transition: transform 0.3s ease;
    display: flex;
    flex-direction: column;
  }
  
  .slide-panel.active {
    transform: translateX(0);
  }
  
  .slide-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid #e2e8f0;
  }
  
  .slide-panel-title {
    margin: 0;
    font-size: 1.25rem;
    color: #1a202c;
  }
  
  .slide-panel-close {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
    color: #4a5568;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 0.25rem;
  }
  
  .slide-panel-close:hover {
    background-color: #f7fafc;
  }
  
  .slide-panel-content {
    flex: 1;
    overflow-y: auto;
    padding: 1.5rem;
  }