/* SlidePanel.css */
.slide-panel-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s, visibility 0.3s;
  }
  
  .slide-panel-overlay.active {
    opacity: 1;
    visibility: visible;
  }
  
  .slide-panel {
    position: fixed;
    top: 0;
    right: -450px;
    width: 450px;
    height: 100vh;
    background-color: white;
    box-shadow: -2px 0 4px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-out;
    z-index: 1001;
    overflow-y: auto;
  }
  
  .slide-panel.active {
    right: 0;
  }
  
  .slide-panel-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid #e2e8f0;
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 1;
  }
  
  .slide-panel-title {
    font-size: 1.25rem;
    font-weight: 600;
    color: #1a202c;
    margin: 0;
  }
  
  .slide-panel-close {
    background: none;
    border: none;
    padding: 0.5rem;
    cursor: pointer;
    color: #4a5568;
    border-radius: 0.375rem;
    transition: all 0.2s;
  }
  
  .slide-panel-close:hover {
    background-color: #f7fafc;
    color: #1a202c;
  }
  
  .slide-panel-content {
    padding: 1.5rem;
  }
  
  /* Form styling updates */
  .slide-panel .edit-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .slide-panel .form-group {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .slide-panel .form-label {
    font-size: 0.875rem;
    font-weight: 500;
    color: #4a5568;
  }
  
  .slide-panel .edit-input,
  .slide-panel .edit-textarea {
    padding: 0.75rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.375rem;
    font-size: 0.875rem;
    transition: all 0.2s;
  }
  
  .slide-panel .edit-input:focus,
  .slide-panel .edit-textarea:focus {
    outline: none;
    border-color: #4CAF50;
    box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .slide-panel {
      width: 100%;
      right: -100%;
    }
  }
  
  /* Animation for the form elements */
  .slide-panel .form-group {
    opacity: 0;
    transform: translateX(20px);
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .slide-panel.active .form-group {
    opacity: 1;
    transform: translateX(0);
  }
  
  .slide-panel.active .form-group:nth-child(1) { transition-delay: 0.1s; }
  .slide-panel.active .form-group:nth-child(2) { transition-delay: 0.2s; }
  .slide-panel.active .form-group:nth-child(3) { transition-delay: 0.3s; }
  .slide-panel.active .form-group:nth-child(4) { transition-delay: 0.4s; }