/* App.css */

.app-container {
  display: flex;
  height: 100vh;
  width: 100vw;
}

.app-sidebar {
  width: 250px;
  background-color: #062238;
  color: #ffffff;
  flex-shrink: 0;
}

.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: #f9f9f9;
}

.app-container {
  display: flex;
  min-height: 100vh;
}

.main-content {
  flex: 1;
  padding: 20px;
  /* Add any existing styles you have for main-content */
}

.main-content.full-width {
  width: 100%;
}