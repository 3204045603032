@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800&display=swap');

/* Base Container Styles */
.landing-container {
  min-height: 100vh;
  background-color: rgb(249, 250, 251);
  padding: 0;
  margin: 0;
  width: 100%;
  font-family: 'Montserrat', sans-serif;
  overflow-x: hidden;
}

.landing-content {
  width: 100%;
  padding: 4rem;
  display: grid;
  grid-template-columns: 1fr;
  gap: 4rem;
  align-items: center;
}

/* Phone Styles */
.phone-container {
  position: relative;
  max-width: 375px;
  width: 100%;
  margin: 0 auto;
}

.phone-mockup {
  position: relative;
  border-radius: 48px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border: 14px solid #1B2B3A;
}

.phone-notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 8rem;
  background-color: #1B2B3A;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  z-index: 10;
}

.phone-content {
  position: relative;
  aspect-ratio: 375/812;
}

/* Video Styles */
.video-container {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
}

.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* Text Content Styles */
.text-content {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-right: 10rem;
}

.text-section {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    background-color: #f3f4f6;
    padding: 3rem;
    border-radius: 24px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    border: 2px solid #f3f4f6;
  }

.text-section h2 {
  font-size: 2.5rem;
  font-weight: 700;
  color: #1B2B3A;
  margin: 0;
  line-height: 1;
}

.text-section h1 {
  font-size: 6rem;
  font-weight: 800;
  color: #1B2B3A;
  line-height: 0.9;
  letter-spacing: -0.025em;
  margin: 0;
}

/* Tagline Styles */
.tagline {
  display: flex;
  flex-direction: column;
  gap: 0.15rem;
}

.tagline p {
  font-size: 2rem;
  font-weight: 700;
  color: #1B2B3A;
  line-height: 1.1;
  margin: 0;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.highlight {
  color: #009DE0;
}

/* Divider */
.divider {
  width: 60px;
  height: 4px;
  background-color: #1B2B3A;
  margin: 1.5rem 0;
  border-radius: 2px;
}

/* Store Buttons and App Icon */
.store-buttons {
    padding: 3rem;
    border-radius: 24px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
    border: 2px solid #f3f4f6;
    display: flex;
    align-items: center;
    gap: 2rem;
    margin-top: 3rem;
    background-color: #f3f4f6;
    padding: 2rem;
    border-radius: 24px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.05);
  }

.store-buttons img {
  height: 7rem;
  width: auto;
  cursor: pointer;
  transition: all 0.2s ease;
}

.store-buttons img:hover {
  opacity: 0.9;
  transform: scale(1.02);
}

.app-icon img {
  width: 10rem;
  height: 10rem;
  border-radius: 1.5rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1);
}

/* Second Section Styles */
.second-section {
  width: 100%;
  padding: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
}

.section-title {
  font-size: 3.5rem;
  font-weight: 800;
  text-align: center;
  margin-bottom: 4rem;
  color: #1B2B3A;
}
/* Phone Showcase Styles */
.phones-showcase {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem; /* Increased gap for larger phones */
  width: 100%;
  max-width: 1200px; /* Increased max-width to accommodate larger phones */
  margin: 4rem 0;
}

.showcase-phone {
  width: 280px; /* Increased base size */
  position: relative;
  transition: all 0.3s ease;
}

.showcase-phone.left {
  transform: translateX(1rem);
}

.showcase-phone.center {
  width: 340px; /* Increased center phone size */
  z-index: 2;
}

.showcase-phone.right {
  transform: translateX(-1rem);
}

.showcase-frame {
  position: relative;
  border-radius: 48px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  border: 14px solid #1B2B3A;
  aspect-ratio: 375/812;
}

.showcase-screen {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.showcase-notch {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 1.5rem;
  width: 8rem;
  background-color: #1B2B3A;
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  z-index: 10;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
  .showcase-phone {
    width: 240px;
  }
  
  .showcase-phone.center {
    width: 300px;
  }
}

@media (max-width: 1024px) {
  .showcase-phone {
    width: 220px;
  }
  
  .showcase-phone.center {
    width: 280px;
  }
}

@media (max-width: 768px) {
  .phones-showcase {
    flex-direction: column;
    gap: 3rem;
  }

  .showcase-phone {
    width: 200px;
    transform: none !important;
  }
  
  .showcase-phone.center {
    width: 260px;
  }
}

/* Footer Text Section */
.footer-text-section {
  width: 100%;
  padding: 4rem;
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 0;
}

.footer-text-container {
  width: 100%;
  max-width: 1200px;
}

.footer-heading {
  font-size: 3.5rem;
  font-weight: 800;
  line-height: 1.2;
  margin: 0;
  letter-spacing: -0.02em;
}

/* Responsive Styles */
@media (min-width: 1024px) {
  .landing-content {
    grid-template-columns: repeat(2, 1fr);
  }

  .text-section h2 {
    font-size: 2.75rem;
  }

  .text-section h1 {
    font-size: 7rem;
  }

  .tagline p {
    font-size: 2.25rem;
  }
}

@media (max-width: 768px) {
  .landing-content,
  .second-section,
  .footer-text-section {
    padding: 2rem;
  }

  .text-section {
    gap: 0.2rem;
  }

  .text-section h2 {
    font-size: 2rem;
  }

  .text-section h1 {
    font-size: 4.5rem;
  }

  .tagline p {
    font-size: 1.75rem;
  }

  .store-buttons {
    gap: 1.5rem;
  }

  .store-buttons img {
    height: 5rem;
  }

  .app-icon img {
    width: 4rem;
    height: 4rem;
  }

  .phones-showcase {
    flex-direction: column;
    gap: 3rem;
  }

  .showcase-phone {
    transform: none !important;
    width: 200px;
  }

  .section-title {
    font-size: 2rem;
  }

  .footer-heading {
    font-size: 2rem;
  }
}