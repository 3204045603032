/* PlaceEditor.css */

.place-editor {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .place-editor h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .place-editor label {
    display: block;
    font-weight: bold;
    margin-top: 10px;
    color: #333;
  }
  
  .place-editor input, .place-editor textarea {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 16px;
  }
  
  .place-editor textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  .save-button, .cancel-button {
    padding: 10px 15px;
    margin-top: 15px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }
  
  .save-button {
    background-color: #28a745;
    color: white;
  }
  
  .cancel-button {
    background-color: #dc3545;
    color: white;
    margin-left: 10px;
  }
  