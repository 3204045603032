.login-container {
  display: flex;
  height: 100vh;
}

.login-form-container {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  background-color: white;
}

.login-form {
  max-width: 400px;
  width: 100%;
}

.logo {
  width: 40px;
  height: 40px;
  margin-bottom: 2rem;
}

.form-title {
  font-size: 2rem;
  font-weight: bold;
  color: #1f2937;
  margin-bottom: 1rem;
}

.form-subtitle {
  color: #4b5563;
  margin-bottom: 2rem;
}

.input-group {
  margin-bottom: 1rem;
}

.input-label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #374151;
}

.input-field {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
  font-size: 1rem;
  background-color: #F8FAFC;
}

.password-input-container {
  position: relative;
}

.password-toggle {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  cursor: pointer;
}

.login-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.remember-me {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.login-button {
  width: 100%;
  padding: 0.75rem;
  background-color: #062238;
  color: white;
  border: none;
  border-radius: 0.375rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.login-button:hover {
  background-color: #4338ca;
}

.register-link {
  text-align: center;
  margin-top: 2rem;
  color: #4b5563;
}


.decorative-image {
  width: 50%;
  background-color: #062238;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 4rem;
}

.text-content-login {
  color: white;
  background-color: #062238;

}

.text-section-login {
  margin-bottom: 3rem;
  background-color: #062238;
  
}

.text-section h2 {
  font-size: 2.75rem;
  font-weight: 500;
  color: white;
  margin: 0;
}

.text-section h1 {
  font-size: 4.5rem;
  font-weight: 700;
  color: white;
  margin: 1rem 0;
}

.divider-login {
  width: 60px;
  height: 2px;
color: white;
background-color: white;
  margin: 1.5rem 0;
}

.tagline {
  margin-top: 1rem;
}
.tagline-login {
  margin-top: 1rem;
  font-size: 2.5rem;
  font-weight: 600;
  color: #2ca3da;
  margin: 0;
}

.tagline p {
  font-size: 2.5rem;
  font-weight: 600;
  color: #60A5FA;
  margin: 0;
}

.store-buttons-login {
  display: flex;
  gap: 1rem;
  margin-top: 3rem;
  background-color: #062238;

}

.store-buttons-login img {
  height: 4.5rem;
  width: auto;
  background-color: #062238;

}


.text-section-login h2 {
  font-size: 2.75rem;
  font-weight: 500;
  color: white;
  margin: 0;
}

.text-section-login h1 {
  font-size: 4.5rem;
  font-weight: 700;
  color: white;
  margin: 1rem 0;
}
