/* src/components/UserManager/UserManager.css */
/* Inherit existing styles from place manager */
.place-manager {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .place-manager-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  /* Additional styles specific to user management */
  .users-list {
    background: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  }
  
  .users-list table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .users-list th,
  .users-list td {
    padding: 12px;
    text-align: left;
    border-bottom: 1px solid #eee;
  }
  
  .users-list th {
    background-color: #f8f9fa;
    font-weight: 500;
    color: #333;
  }
  
  .users-list select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: white;
  }
  
  .delete-button {
    padding: 6px 12px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .delete-button:hover {
    background-color: #c82333;
  }
  
  .error-message {
    background-color: #fff3f3;
    border-left: 4px solid #dc3545;
    color: #dc3545;
    padding: 12px;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  
  .loading {
    text-align: center;
    padding: 20px;
    color: #666;
  }
  
  .access-denied {
    text-align: center;
    padding: 40px;
    color: #dc3545;
    font-weight: 500;
  }
  
  @media (max-width: 768px) {
    .users-list {
      padding: 10px;
    }
    
    .users-list th,
    .users-list td {
      padding: 8px;
    }
    
    .delete-button {
      padding: 4px 8px;
    }
  }

  /* Base styles */
.place-manager {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

.place-manager-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* Table styles */
.users-list {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  width: 100%;
  border-collapse: collapse;
}

.users-list th,
.users-list td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

.users-list th {
  background-color: #f8f9fa;
  font-weight: 500;
  color: #333;
}

/* Form and input styles */
.form-group {
  margin-bottom: 1rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

/* Button styles */
.add-place-button,
.save-button,
.cancel-button,
.delete-button,
.back-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  gap: 8px;
}

.add-place-button {
  background-color: #4CAF50;
  color: white;
}

.add-place-button:hover {
  background-color: #45a049;
}

.save-button {
  background-color: #007bff;
  color: white;
}

.save-button:hover {
  background-color: #0056b3;
}

.cancel-button,
.delete-button {
  background-color: #dc3545;
  color: white;
}

.cancel-button:hover,
.delete-button:hover {
  background-color: #c82333;
}

.back-button {
  background-color: #6c757d;
  color: white;
}

.back-button:hover {
  background-color: #5a6268;
}

/* Utility classes */
.error-message {
  background-color: #fff3f3;
  border-left: 4px solid #dc3545;
  color: #dc3545;
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.loading {
  text-align: center;
  padding: 20px;
  color: #666;
}

/* Slide Panel styles */
.slide-panel-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease;
  z-index: 1000;
}

.slide-panel-overlay.active {
  opacity: 1;
  pointer-events: auto;
}

.slide-panel {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: 400px;
  background: white;
  box-shadow: -2px 0 4px rgba(0,0,0,0.1);
  transform: translateX(100%);
  transition: transform 0.3s ease;
  display: flex;
  flex-direction: column;
  z-index: 1001;
}

.slide-panel.active {
  transform: translateX(0);
}

.slide-panel-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  border-bottom: 1px solid #e2e8f0;
}

.slide-panel-title {
  margin: 0;
  font-size: 1.25rem;
  color: #1a202c;
}

.slide-panel-content {
  flex: 1;
  overflow-y: auto;
  padding: 1.5rem;
}

.button-group {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

@media (max-width: 768px) {
  .slide-panel {
    width: 100%;
  }
  
  .users-list {
    padding: 10px;
  }
  
  .users-list th,
  .users-list td {
    padding: 8px;
  }
}