/* components/CityManager.css */
.edit-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .edit-input {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    font-size: 1rem;
  }
  
  .edit-textarea {
    padding: 0.5rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    font-size: 1rem;
    min-height: 100px;
    resize: vertical;
  }
  
  .edit-actions {
    display: flex;
    gap: 0.5rem;
    justify-content: flex-end;
    margin-top: 1rem;
  }
  
  .save-button, .cancel-button {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;
    font-weight: 500;
  }
  
  .save-button {
    background-color: #4CAF50;
    color: white;
  }
  
  .cancel-button {
    background-color: #f44336;
    color: white;
  }
  
  .card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
  }
  
  .edit-button {
    background: none;
    border: none;
    color: #4a5568;
    cursor: pointer;
    padding: 0.25rem;
  }
  
  .city-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
    border-radius: 0.25rem;
    margin-top: 1rem;
  }
  /* Ajoutez ces styles à votre CityManager.css */
.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.dashboard-card {
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.add-city-section {
  display: flex; /* Active le flexbox */
  justify-content: flex-end; /* Aligne le contenu à droite */
  padding: 2rem 2rem 0 2rem;
}

.add-city-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.add-city-button:hover {
  background-color: #45a049; /* Plus foncé au survol */
}


.card-title {
  margin: 0;
  font-size: 1.25rem;
  color: #1a202c;
}

.card-content p {
  color: #4a5568;
  margin: 0.5rem 0;
}

.delete-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f44336; /* Red color for delete action */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.delete-button:hover {
  background-color: #e53935; /* Slightly darker red on hover */
}

.edit-button2 {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4CAF50; /* Red color for delete action */
  color: white;
  border: none;
  border-radius: 0.25rem;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
}

.edit-button:hover2 {
  background-color: #45a049; /* Slightly darker green on hover */
}

/* CityManager.css */
/* Layout */
.dashboard-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
  padding: 2rem;
}

.dashboard-card {
  background: white;
  border-radius: 0.5rem;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.dashboard-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
}

.add-city-section {
  display: flex;
  justify-content: flex-end;
  padding: 2rem 2rem 0 2rem;
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: #f8f9fa;
}

/* Card Elements */
.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
}

.card-title {
  margin: 0;
  font-size: 1.25rem;
  color: #1a202c;
  font-weight: 600;
}

.card-content p {
  color: #4a5568;
  margin: 0.5rem 0;
  line-height: 1.5;
}

.city-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 0.5rem;
  margin-top: 1rem;
  border: 1px solid #e2e8f0;
}

/* Buttons */
.add-city-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.75rem 1.5rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.add-city-button:hover {
  background-color: #45a049;
  transform: translateY(-1px);
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.add-city-button:active {
  transform: translateY(0);
}

.edit-button2, .save-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.edit-button2:hover, .save-button:hover {
  background-color: #45a049;
  transform: translateY(-1px);
}

.delete-button, .cancel-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.delete-button:hover, .cancel-button:hover {
  background-color: #e53935;
  transform: translateY(-1px);
}

/* Form Elements */
.edit-form {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.edit-input {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.edit-textarea {
  padding: 0.75rem;
  border: 1px solid #e2e8f0;
  border-radius: 0.5rem;
  font-size: 1rem;
  min-height: 100px;
  resize: vertical;
  transition: border-color 0.2s, box-shadow 0.2s;
}

.edit-input:focus, .edit-textarea:focus {
  outline: none;
  border-color: #4CAF50;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.2);
}

.edit-actions {
  display: flex;
  gap: 0.75rem;
  justify-content: flex-end;
  margin-top: 1.5rem;
}

/* Loading State */
.loading-spinner {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  color: #4CAF50;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .dashboard-grid {
    grid-template-columns: 1fr;
    padding: 1rem;
  }
  
  .add-city-section {
    padding: 1rem 1rem 0 1rem;
  }
  
  .edit-actions {
    flex-direction: column;
  }
  
  .edit-actions button {
    width: 100%;
  }
}