/* AppSidebar.css */

.app-sidebar {
  width: 250px;
  background-color: #062238;
  color: #ffffff;
  height: 100vh;
  padding: 1rem;
  display: flex;
  flex-direction: column;
}

.sidebar-header {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
}

.sidebar-header .user-icon {
  background-color: #0c3755;
  border-radius: 50%;
  padding: 0.5rem;
  margin-right: 0.75rem;
}

.sidebar-header .username {
  font-weight: 600;
  font-size: 1.1rem;
}

.sidebar-nav {
  flex-grow: 1;
}

.nav-list {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  margin-bottom: 0.5rem;
}

.nav-link {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #ffffff;
  text-decoration: none;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;
  font-size: 1rem;
}

.nav-link:hover {
  background-color: #0c3755;
}

.nav-link .icon {
  margin-right: 0.75rem;
}

.nav-link.active {
  background-color: #062238;
}

.logout-button {
  display: flex;
  align-items: center;
  padding: 0.75rem 1rem;
  color: #ffffff;
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  border-radius: 0.375rem;
  transition: background-color 0.2s ease;
  font-size: 1rem;
}

.logout-button:hover {
  background-color: #062238;
}

.logout-button .icon {
  margin-right: 0.75rem;
}
